// @use  '';
@use "../mixins/breakpoint.scss" as bp;
$bp: (
  base: 0px,
  sm: 540px,
  md: 992px,
  lg: 1280px,
);


.container-lg {
  max-width: calc(1209px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-md {
  max-width: calc(924px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-lg-2 {
  max-width: calc(1154px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-lg-3 {
  max-width: calc(1064px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-lg-4 {
  max-width: calc(1114px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-lg-5 {
  max-width: calc(1107px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-lg-6 {
  max-width: calc(1113px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-sm {
  max-width: calc(660px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.container-lg-7 {
  max-width: calc(1195px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}