@forward "./breakpoint.scss";

@forward './columns.scss';

@forward './typography.scss';

#root {
    overflow-x: hidden;
}

input[type=checkbox]+label {
    display: block;
    cursor: pointer;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox]+label:before {
    content: "\2713";
    font-weight: 700;
    border: 1px solid #000000;
    color: #ffffff;
    border-radius: 2px;
    display: flex;
    width: 15px;
    height: 15px;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
    font-size: 11px !important;
    justify-content: center;
    align-items: center;
}


input[type=checkbox]:checked+label:before {
    background-color: #000;
    color: #fff;
}

input[type=checkbox]:checked+label:after {
    display: block;
    position: absolute;
    top: 11px;
    left: 16px;
    width: 4px;
    height: 10px;
    border: 1px solid none !important;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}