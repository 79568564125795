@mixin pointer {
    cursor: pointer;
}

@mixin block {
    display: block;
}

@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin flex-between {
    @include flex-center();
    justify-content: space-between;
}

@mixin text-center {
    text-align: center;
}

@mixin text-shadow {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

}