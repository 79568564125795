.md-button {
    button {
        border: none;
        background-color: var(--black-color);
        color: var(--white-color);
        padding: 7px 14px;
        @include text-center;
        @include pointer;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        font-family: "Aileron-Bold";

    }
}