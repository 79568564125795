@use '../mixins/breakpoint.scss';


// font-family: 'PT Serif', serif;

// font-family: 'Poppins', sans-serif;


@mixin normal-13 {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    font-family: "Aileron-Light";
}

@mixin normal-13-one {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    font-family: "Aileron-Regular";

}

@mixin font-16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    font-family: "Aileron-Regular";

}

@mixin bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: "Aileron-Bold";
}

@mixin bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    font-family: "Aileron-Bold";
}

@mixin bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: "Aileron-Bold";
}

@mixin bold-36 {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    font-family: "Aileron-Bold";
}

@mixin normal-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    font-family: "Aileron-Regular";

}

@mixin normal-20 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    font-family: "Aileron-Regular";

}

