@font-face {
    font-family: "Aileron-Light";
    src: url("../../assets/font/Aileron-Light.otf");
}

@font-face {
    font-family: "Aileron-Bold";
    src: url("../../assets/font/Aileron-Bold.otf");
}

@font-face {
    font-family: "Aileron-Regular";
    src: url("../../assets/font/Aileron-Regular.otf");
}

/* pt-serif-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#PTSerif') format('svg'); /* Legacy iOS */
  }
  /* pt-serif-700 - latin-ext_latin_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#PTSerif') format('svg'); /* Legacy iOS */
  }
  /* pt-serif-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 700;
    src: url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#PTSerif') format('svg'); /* Legacy iOS */
  }
  /* pt-serif-italic - latin-ext_latin_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 400;
    src: url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/pt-serif-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#PTSerif') format('svg'); /* Legacy iOS */
  }

  /* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/font/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-500 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/font/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/font/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/font/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('../../assets/font/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/poppins-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/poppins-v20-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/poppins-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* work-sans-regular - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/font/work-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/work-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/work-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/work-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/work-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/work-sans-v18-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-500 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/font/work-sans-v18-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/work-sans-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/work-sans-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/work-sans-v18-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/work-sans-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/work-sans-v18-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-600 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/font/work-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/font/work-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/font/work-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/font/work-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../assets/font/work-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/font/work-sans-v18-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
  }