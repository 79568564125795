body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.container-loading {
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.loading-img {
  max-width: 8rem;
}

@media (max-width: 767px) {
  .loading-img {
    max-width: 6rem;
  }
}

div,
a,
span,
img {
  -webkit-tap-highlight-color: transparent !important;
}

.to-portrait {
  display: none;
}

@media (max-width: 996px) and (orientation: landscape) {
  body {
    overflow-y: hidden;
  }
  .to-portrait {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    background-color: black;
    overflow-x: hidden;
  }

  #main-container {
    display: none;
  }
}
